import Qrious from 'qrious';
import uts from '@linecorp/uts';

const getHTML = (options) => {
  const { t, liffInfo, qrcodeUrl } = options;
  const description = t['liff.landingpage.pc.miniApp.description'].replace(
    '{0}',
    liffInfo.liffAppDescription,
  );
  const template = `
    <section class="main pc-version">
      <section class="content">
        <div class="logo"></div>
        <h1 class="tl">${t['liff.landingpage.pc.title']}</h1>
        <div class="qrcode">
          <img src="${qrcodeUrl}" />
        </div>
        <p class="subtl">${t['liff.landingpage.pc.qrcode.description']}</p>
        <p class="subtl">${description}</p>
      </section>
    </section>
  `;

  return template;
};

window.addEventListener('load', () => {
  const urlToLiffApp = window.liffInfo.liffAppUrl;
  const qrcode = new Qrious({
    value: urlToLiffApp,
    size: 500,
  });

  document.body.innerHTML = getHTML({
    t: window.translations,
    liffInfo: window.liffInfo,
    qrcodeUrl: qrcode.toDataURL(),
  });

  uts.sendPageview({
    screenName: 'pc_lp',
    params: { liff_id: window.liffInfo.liffId },
  });
});
